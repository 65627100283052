import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import { getSeoConfig } from '../../constants/defaultSeoProps';
import DataMLAI from '../../assets/services/DataMLAI.png';
import CloudServices from '../../assets/services/CloudServices.png';
import StrategyReview from '../../assets/services/StrategyReview.png';
import TechnologyTransformation from '../../assets/services/TechnologyTransformation.png';
import ProductEngineering from '../../assets/services/ProductEngineering.png';
import QAEngineering from '../../assets/services/QAEngineering.png';
import ManagedServices from '../../assets/services/ManagedServices.png';
import StaffingSolution from '../../assets/services/StaffingSolution.png';
import SecurityServices from '../../assets/services/SecurityServices.png';
import ITServices from '../../assets/services/ITServices.png';
import CloudflareServices from '../../assets/services/CloudflareServices.png';
import Partner from '../../components/Partner';
import { partnersData } from '../Contact/partnersData';
import s from './Services.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  detailsDescription: PropTypes.shape({}).isRequired,

  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/services.png',
  ogTitle: 'Our Services | Tech Holding',
  ogDesc:
    "Elevate your business with TechHolding's comprehensive services. From software to product development, unlock innovative solutions tailored to your needs",
  ogPath: '/services',
};

const updatedSeo = getSeoConfig(seoParams);

const Services = ({ title, subheader, companyQuote }) => {
  return (
    <Layout
      currentPage="/services"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <PageHeader className={s.pageHeader} title={title} description={subheader} />
      <div className={s.section}>
        <div className={s.container}>
          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img className={s.emoji} src={DataMLAI} alt="" />
            </div>
            <h2>Data, ML & AI</h2>
            <p>Unlock the potential of your data with our expert services:</p>
            <ul>
              <li>
                <strong>Data Engineering: </strong>
                Build robust data pipelines that ensure data accuracy and accessibility.
              </li>
              <li>
                <strong>Data Migration: </strong>
                Seamlessly transfer data across systems to enhance business operations.
              </li>
              <li>
                <strong>Data Warehouse Solutions: </strong>
                Consolidate your data into a single, query-able repository optimized for reporting
                and analysis.
              </li>
              <li>
                <strong>Machine Learning & AI: </strong>
                Implement predictive models and intelligent algorithms that turn data into
                actionable insights.
              </li>
              <li>
                <strong>Generative AI (GenAI): </strong>
                Leverage cutting-edge&nbsp;
                <a href="/services/ai">
                  <strong>AI</strong>
                </a>
                &nbsp;to generate content, automate tasks, and innovate processes.
              </li>
              <li>
                <strong>Data Science: </strong>
                Apply scientific methods, algorithms, and systems to extract knowledge and insights
                from structured and unstructured data.
              </li>
              <li>
                <strong>Data Mesh: </strong>
                Facilitate a decentralized approach to data management that enhances accessibility
                and accelerates innovation across your enterprise using Data Mesh.
              </li>
              <li>
                <strong>Snowflake: </strong>
                Leverage our Services to harness the power of&nbsp;
                <a href="/services/snowflake">
                  <strong>Snowflake</strong>
                </a>
                , transforming your data into actionable insights with our expert certified
                Snowflake specialists.
              </li>
              <li>
                <strong>Business Intelligence: </strong>
                Gain valuable business insights through data visualization tools and dashboards.
              </li>
            </ul>
          </div>

          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img className={s.emoji} src={CloudServices} alt="" />
            </div>
            <h2>Cloud Services</h2>
            <p>
              Elevate your business with our cloud computing expertise in partnership with&nbsp;
              <a href="/services/aws">
                <strong>AWS</strong>
              </a>
              &nbsp;and&nbsp;
              <a href="/services/gcp">
                <strong>GCP.</strong>
              </a>
            </p>
            <ul>
              <li>
                <strong>Migration: </strong>
                Move your applications, data, and workloads to the cloud with minimal disruption.
              </li>
              <li>
                <strong>Optimization: </strong>
                Fine-tune your cloud environment for enhanced performance and cost-efficiency.
              </li>
              <li>
                <strong>Operations: </strong>
                Manage your cloud infrastructure to ensure smooth, continuous operations.
              </li>

              <li>
                <strong>Serverless Solutions: </strong>
                Build and run applications without thinking about servers.
              </li>
              <li>
                <strong>Re-Architecture: </strong>
                Transform your legacy applications to be more agile, scalable, and cloud-compatible.
              </li>
            </ul>
          </div>

          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img className={s.emoji} src={StrategyReview} alt="" />
            </div>
            <h2>Strategy & Review</h2>
            <p>Strategize for success with our comprehensive planning services:</p>

            <ul>
              <li>
                <strong>Product Strategy: </strong>
                Define the roadmap and vision for your product to ensure market fit and growth.
              </li>
              <li>
                <strong>Architecture Reviews: </strong>
                Assess your current technology architecture and identify improvements.
              </li>
              <li>
                <strong>Infrastructure Assessment: </strong>
                Analyze and evaluate your existing cloud infrastructure to identify opportunities
                for improvement, cost savings, and potential security risks.
              </li>
              <li>
                <strong>Process Reengineering: </strong>
                Revamp your business processes for maximum efficiency and effectiveness.
              </li>
            </ul>
          </div>

          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img className={s.emoji} src={TechnologyTransformation} alt="" />
            </div>
            <h2>Technology Transformation</h2>
            <p>Revolutionize your technology landscape:</p>

            <ul>
              <li>
                <strong>App Modernization: </strong>
                Upgrade your legacy systems to newer, more efficient technologies.
              </li>
              <li>
                <strong>Tech-stack Upgrade: </strong>
                Stay ahead of the curve by updating your tech stack to the latest standards.
              </li>
              <li>
                <strong>Bluetooth/BLE Solutions: </strong>
                Implement secure, low-energy Bluetooth solutions to enable seamless device
                connectivity and real-time data sharing.
              </li>
              <li>
                <strong>Geo-Location Services: </strong>
                Incorporate precise go-location technology to enhance app functionality with
                location-based features like real-time tracking and geo-fencing.
              </li>
            </ul>
          </div>

          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img className={s.emoji} src={ProductEngineering} alt="" />
            </div>
            <h2>Product Engineering</h2>
            <p>Turn ideas into successful products:</p>

            <ul>
              <li>
                <strong>From Concept to Launch: </strong>
                Develop your product from the ground up, focusing on scalability and user
                experience.
              </li>
              <li>
                <strong>User Stories to Architecture and Implementation: </strong>
                Craft detailed plans and robust architectures followed by high-quality
                implementation.
              </li>
              <li>
                <strong>Custom Native and Cross-Platform Mobile Applications: </strong>
                Design and develop cutting-edge mobile apps for Android and iOS or leverage
                frameworks like React Native and Flutter to deliver high-quality, cross-platform
                apps that reduce development time and costs.
              </li>
              <li>
                <strong>Operation: </strong>
                Ensure your product operates flawlessly with ongoing support and maintenance.
              </li>
            </ul>
          </div>

          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img className={s.emoji} src={QAEngineering} alt="" />
            </div>
            <h2>QA Engineering</h2>
            <p>Ensure quality, from concept to delivery:</p>

            <ul>
              <li>
                <strong>Diverse Testing Approaches: </strong>
                Employ a range of testing methodologies, including black-box, white-box and
                regression testing, to cover all facets of software quality and functionality.
              </li>
              <li>
                <strong>Automated Testing Frameworks: </strong>
                Utilize bleeding-edge automated testing methodologies to rectify defects early in
                the development cycle, ensuring product robustness.
              </li>
              <li>
                <strong>Effective Bug Tracking: </strong>
                Implement systematic bug reporting and tracking mechanisms to ensure all issues are
                identified, logged and addressed promptly and properly.
              </li>
              <li>
                <strong>QA Documentation: </strong>
                Produce comprehensive test plans, cases and reports that clearly map out testing
                strategies and results for full transparency.
              </li>
            </ul>
          </div>

          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img className={s.emoji} src={ManagedServices} alt="" />
            </div>
            <h2>Managed Services</h2>
            <p>Keep your operations running without a hitch:</p>

            <ul>
              <li>
                <strong>24x7 Monitoring: </strong>
                Keep tabs on your applications and systems around the clock.
              </li>
              <li>
                <strong>L1, L2 Support: </strong>
                Get first-class support and problem resolution.
              </li>
              <li>
                <strong>Infrastructure Management: </strong>
                Ensure your IT infrastructure is always reliable and efficient.
              </li>

              <li>
                <strong>Service Desk: </strong>
                Expert agents are available during specified hours to efficiently handle your calls
                and deliver top-tier support services.
              </li>
            </ul>
          </div>

          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img className={s.emoji} src={StaffingSolution} alt="" />
            </div>
            <h2>Staffing Solution</h2>
            <p>Augment your teams with expert resources:</p>

            <ul>
              <li>
                <strong>US-Based Team Members: </strong>
                Access highly skilled professionals located in the US.
              </li>
              <li>
                <strong>Nearshore (Mexico) and Offshore (India) Staffing: </strong>
                Extend your team with cost-effective, skilled resources from our nearshore and
                offshore locations.
              </li>
            </ul>
          </div>

          {/* Security Services */}
          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img className={s.emoji} src={SecurityServices} alt="Security Services" />
            </div>
            <h2>
              <a href="/services/security">Security Services</a>
            </h2>
            <p>
              Help protect your business with comprehensive security solutions. Our security team
              provides managed security operations, compliance automation, and secure development
              practices.
            </p>

            <ul>
              <li>
                <strong>
                  <a href="/services/security/mssp">Managed Security Services </a>
                </strong>
                <br />
                Get 24/7 security monitoring and threat response from our expert SOC team
              </li>
              <li>
                <strong>
                  <a href="/services/security/compliance">Compliance Services </a>
                </strong>
                <br />
                Achieve and maintain security certifications efficiently with our Vanta-powered
                solutions
              </li>
              <li>
                <strong>
                  <a href="/services/security/devsecops">DevSecOps </a>
                </strong>
                <br />
                Integrate security into your development pipeline without slowing down delivery
              </li>
            </ul>
          </div>

          {/* ITSM Solutions */}
          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img className={s.emoji} src={ITServices} alt="IT Services" />
            </div>
            <h2>
              <a href="/services/itsm">Comprehensive IT Service Management (ITSM) Solutions</a>
            </h2>
            <h4>Transforming IT Operations with Automation, Security, and 24x7 Support</h4>
            <ul>
              <li>
                Keep your business running seamlessly with our full-spectrum IT Service Management
                (ITSM) solutions. At Tech Holding, we specialize in 24x7 IT operations, MSP
                services, and strategic partnerships with industry-leading vendors like Cloudflare,
                Kandji, CrowdStrike, Microsoft Intune, Apple Business Manager, Jamf, Vanta, and
                more.
              </li>
              <li>
                Our tailored ITSM solutions help businesses optimize IT processes, enhance security,
                and maintain compliance—all while reducing operational complexity. Whether you’re
                looking for proactive monitoring, endpoint management, cloud security, or IT
                automation, our expert team ensures your IT infrastructure stays resilient and
                future-ready.
              </li>
            </ul>
          </div>

          {/* Cloudflare Professional Services */}
          <div className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.image}>
              <img
                className={s.emoji}
                src={CloudflareServices}
                alt="Cloudflare Professional Services"
              />
            </div>
            <h2>
              <a href="/services/cloudflare">Cloudflare Professional Services</a>
            </h2>
            <h4>Comprehensive Cloudflare Cloud Solutions Tailored for Your Business</h4>
            <ul>
              <li>
                <a href="/services/cloudflare/appsec">
                  <strong>Application Security</strong>
                </a>
                <br />
                Protect Your Digital Frontiers
              </li>
              <li>
                <a href="/services/cloudflare/performance">
                  <strong>Application Performance</strong>
                </a>
                <br />
                Accelerate and Stabilize Your Digital Services
              </li>
              <li>
                <a href="/services/cloudflare/sase">
                  <strong>Secure Access Service Edge (SASE)</strong>
                </a>
                <br />
                Streamline Network Security and Access
              </li>
            </ul>
          </div>

          <div className={s.blockFooter}>
            <p>
              At Tech Holding, we are committed to helping our clients navigate their digital
              transformation journeys with ease and confidence. Whether you are looking to enhance
              your data capabilities, migrate to the cloud, reengineer your business processes, or
              simply extend your team with skilled professionals, we have the expertise and
              solutions to support your goals.
            </p>
          </div>
        </div>
      </div>
      <div className={s.partners}>
        <Partner partnersImages={partnersData} />
      </div>
    </Layout>
  );
};

Services.propTypes = propTypes;
export default Services;
